import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import Location  from "../components/services/location_consultoria"
import { CgArrowLongRight } from "react-icons/cg"


const Consultoria = () => {
  const data = useStaticQuery(graphql`
    query Images_consultoria {
      image: allFile(
        filter: { relativeDirectory: { eq: "consultoria" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Consultoría Tecnológica y Asesoramiento Digital | Qualoom</title>
        <meta name="title" content="Consultoría Tecnológica y Asesoramiento Digital | Qualoom"/>
        <meta name="description" content="¿Estás buscando una Consultoría Tecnológica? En Qualoom te Asesoramos en el Proceso de Transformación Digital de tu Empresa. ¡Infórmate YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/consultoria-tecnologica/"/>
        <link rel="canonical" href="https://www.qualoom.es/consultoria-tecnologica/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/consultoria-tecnologica/"/>
        <meta property="og:title" content="Consultoría Tecnológica y Asesoramiento Digital | Qualoom"/>
        <meta property="og:description" content="¿Estás buscando una Consultoría Tecnológica? En Qualoom te Asesoramos en el Proceso de Transformación Digital de tu Empresa. ¡Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2KSu31ogFxZoi4VY54hFVu/24484f8e8b08fc7af28a0696a809d6ff/tw_cards_consultoria.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/consultoria-tecnologica/"/>
        <meta property="twitter:title" content="Consultoría Tecnológica y Asesoramiento Digital | Qualoom"/>
        <meta property="twitter:description" content="¿Estás buscando una Consultoría Tecnológica? En Qualoom te Asesoramos en el Proceso de Transformación Digital de tu Empresa. ¡Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2KSu31ogFxZoi4VY54hFVu/24484f8e8b08fc7af28a0696a809d6ff/tw_cards_consultoria.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/consultoria-tecnologica/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Consultoría tecnológica", "item": {"@id": "https://www.qualoom.es/consultoria-tecnologica/", "name": "Consultoría tecnológica"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Una consultoría tecnológica a tu disposición</h1>
              <p>El área de las tecnologías de la información es nuestro ecosistema nativo. Podemos ayudarte 
              a alcanzar los retos que tu organización establezca y siempre con un trato personalizado. Te 
              invitamos a conocernos, a que nos cuentes tus retos y si no encuentras el servicio en la web 
              que necesitas nos consultes igualmente. Estamos seguros de poder ayudarte.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Qué es una consultoría tecnológica?</h2><hr></hr>
          <p>Es un servicio ofrecido para asesorar a tu empresa sobre la mejor estrategia en utilización y adopción de tecnologías de la información con el fin de alcanzar tus objetivos de negocio y organizacionales. Todo ello a través del uso de soluciones personalizadas o de terceras partes, con profesionales y a través de métodos y procedimientos que aseguren el éxito.</p><br/>
          <div className="section-main">
            <div className="section-img consultoria"></div>
            <div className="section-text">
              <h3>¿Para qué sirve una consultora tecnológica?</h3>
              <p>En Qualoom te ayudaremos con tus objetivos con nuestro asesoramiento en procesos de transformación digital, optimizando tus infraestructuras y aplicaciones, cultura digital, formación a usuarios, diseñando tus arquitecturas críticas, revisando las existentes y siempre  garantizando la mejor calidad.</p>
              <a href="/consultoria-tecnologica/transformacion-digital/">
                <p>Descubre más sobre nuestros servicios de Transformación Digital &nbsp;<CgArrowLongRight/></p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Consultoría en Transformación digital</h2><hr></hr>
          <div className="section-title"><h3>¿Qué ofrece Qualoom?</h3></div>
          <div className="section-items">
            <div className="section-item gray">
              <h4>Asesoramiento en procesos de transformación IT</h4>
              <p>Qualoom puede ayudarte en el proceso de transformación digital de tu organización y siempre desde un enfoque clave, un equipo involucrado con una cultura de la empresa, ágil, innovador y eficiente. A través de la implantación de metodologías ágiles, nuestro equipo PMP y el diseño e implementación de procesos a la medida de tu organización serán la vía para alcanzar los objetivos de transformación.</p>
            </div>
            <div className="section-item blue">
              <h4>Optimización de infraestructuras y aplicaciones</h4>
              <p>Problemas de estabilidad, rendimiento o escalabilidad serán resueltos tanto si tiene una arquitectura en la nube, híbrida o tradicional a través del análisis de su arquitectura. Ajustamos todos los componentes de tu sistema para que funcionen con la mayor precisión y con el menor coste posible.</p>
            </div>
            <div className="section-item blue">
              <h4>Diseño de arquitecturas altamente escalables y en alta disponibilidad</h4>
              <p>Un servicio global y disponible las 24 horas del día los 365 días del año puede requerir el aseguramiento de la calidad del mismo con independencia del número de usuarios que la utilicen. Nuestro equipo de especialistas diseñarán una arquitectura capaz de resistir un número ilimitado de usuarios, así como una arquitectura tolerante a fallos con el menor coste posible.</p>
            </div>
            <div className="section-item gray">
              <h4>Pruebas de estrés y tolerancia a fallos</h4>
              <p>¿Conoce los límites de su sistema? Es capaz de soportar el uso masivo de usuarios en horas concretas del día o de manera sostenida en el tiempo. ¿Sabes a partir de qué momento el sistema dejaría de estar operativo y de ofrecer un correcto nivel de servicio? A través de la definición de planes de prueba y de estrés desvelaremos todas estas incógnitas para mejorar la satisfacción de sus clientes y dimensionar sus sistemas de la manera más óptima.</p>
            </div>
            <div className="section-item gray">
              <h4>DevOps, SysOps y SysAdmin</h4>
              <p>Un equipo especializado certificado en DevOps, SysOps y SysAdmin con más de 10 años de experiencia es la garantía para liderar los proyectos más exigentes, empleando las herramientas y tecnologías tradicionales y más novedosas.</p>
            </div>
            <div className="section-item blue">
              <h4>Diseño planes de contingencia y continuidad ante desastres</h4>
              <p>La definición de planes de contingencia y de recuperación ante desastres es clave para el correcto funcionamiento de una organización en el momento de sufrir algún tipo de anomalía, degradación o imprevisto. A través del análisis de sus sistemas y su nivel de criticidad, le asesoramos en su diseño e implantación con el objetivo de disponer siempre de un Plan B.</p>
            </div>
            <div className="section-item blue">
              <h4>Auditoría de sistemas</h4>
              <p>La revisión y certificación de su sistema de información con base en organismos certificadores, auditorías de seguridad y herramientas de análisis, permiten conocer a la organización su nivel de seguridad, mantener el nivel de exigencia y aplicar procedimientos, estándares y soluciones adecuadas en cada caso.</p>
            </div>
            <div className="section-item gray">
              <h4>Tiempo de respuesta inferior a 1 hora. SLAs</h4>
              <p>¿Tus procesos de negocio y sistemas necesitan de un tiempo de respuesta concreto ante incidencias, anomalías o degradaciones? Qualoom pone a tu disposición un equipo de ingenieros con más de 15 años de experiencia en todo tipo de escenarios, con la finalidad de identificar el origen del problema de manera proactiva y mitigar su impacto en la operativa de la organización. Contamos con planes de soporte 8x5, 12x5 y 24x7</p>
            </div>
          </div><br/>
          <h3>¿Por qué necesitas un consultor tecnológico?</h3>
          <p>Invertir en un buen consultor tecnológico te ahorrará tiempo y dinero en tus procesos, porque cuanto más esperes los problemas y fallos pueden ser más complicados de solucionar. Un consultor experto en las tecnologías críticas que necesita tu organización te permitirá garantizar la correcta implantación de herramientas, solución y procedimientos.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Ofrecemos una consultoría tecnológica 360</h2><hr></hr>
          <h3>¿Dónde ofrecemos nuestros servicios?</h3>
          <Location/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Consultoria
